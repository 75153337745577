
<script>
import StudentBaseInfoImport from '../customerService/StudentBaseInfoImport.vue'
import StudentTrainingResultsImport from '../customerService/StudentTrainingResultsImport.vue'
import StudentTrainingResultsExport from '../customerService/StudentTrainingResultsExport.vue'
import CoachHourImport from '../customerService/CoachHourImport.vue'
import ParentInfoExport from '../customerService/ParentInfoExport.vue'
import TrainingPlanUpload from '../coach/TrainingPlanUpload.vue'
import TrainingPlanExport from '../coach/TrainingPlanExport.vue'
import OnCampusTraining from '../coach/OnCampusTraining.vue'
import OffCampusTraining from '../coach/OffCampusTraining.vue'
import UniappHomeManage from '../customerService/UniappHomeManage.vue'
import RollingInfoManage from '../customerService/RollingInfoManage.vue'
import ParentRegisterInfoExport from '../customerService/ParentRegisterInfoExport.vue'
import UserSigInList from '../customerService/UserSigInList.vue'
import CarouselImageManage from '../dataConfig/carouselImageManage/carouselImageManage.vue'
import ScheduleEnterSchool from '../dataConfig/schedule/scheduleEnterSchool.vue'
import ScheduleTrain from '../dataConfig/schedule/scheduleTrain.vue'
import ScheduleTrainMaintenance from '../dataConfig/schedule/scheduleTrainMaintenance.vue'
import TodoMessage from '../dataConfig/messageReminder/todoMessage.vue'
import UpdateMessage from '../dataConfig/messageReminder/updateMessage.vue'
import AssessmentWarning from '../dataConfig/monthlyAssessment/assessmentWarning.vue'
import AssessmentPlanWarning from '../dataConfig/monthlyAssessment/assessmentPlanWarning.vue'
import AssessmentWages from '../dataConfig/monthlyAssessment/assessmentWages.vue'
import AssessmentResults from '../dataConfig/publicityQuery/assessmentResults.vue'
import ProgrammeAchieve from '../dataConfig/publicityQuery/programmeAchieve.vue'
import SummaryReport from '../dataConfig/publicityQuery/summaryReport.vue'
import ManageSystem from '../dataConfig/companyInfo/manageSystem.vue'
import JobManual from '../dataConfig/companyInfo/jobManual.vue'
import PreJobLearning from '../dataConfig/companyInfo/preJobLearning.vue'
import Culture from '../dataConfig/companyBrandPromotion/culture.vue'
import Brand from '../dataConfig/companyBrandPromotion/brand.vue'
import Publicize from '../dataConfig/companyBrandPromotion/brandPromotion.vue'
import AppletImgManage from './AppletImgManage.vue'
import SwiperImg from '../dataConfig/swiperImg/CarouselImgManage.vue'

export default {
  name: 'HomeNav',
  components: {
    StudentBaseInfoImport,
    StudentTrainingResultsImport,
    StudentTrainingResultsExport,
    CoachHourImport,
    ParentInfoExport,
    TrainingPlanUpload,
    TrainingPlanExport,
    OnCampusTraining,
    OffCampusTraining,
    UniappHomeManage,
    RollingInfoManage,
    ParentRegisterInfoExport,
    UserSigInList,
    CarouselImageManage,
    ScheduleEnterSchool,
    ScheduleTrain,
    ScheduleTrainMaintenance,
    TodoMessage,
    UpdateMessage,
    AssessmentWarning,
    AssessmentPlanWarning,
    AssessmentWages,
    AssessmentResults,
    ProgrammeAchieve,
    SummaryReport,
    ManageSystem,
    JobManual,
    PreJobLearning,
    Culture,
    Brand,
    Publicize,
    AppletImgManage,
    SwiperImg,
  },
}
</script>
<script setup>
import { ref } from 'vue'
const studentBaseInfoImportIsShow = ref(false)
const studentTrainingResultsImportIsShow = ref(false)
const studentTrainingResultsExportIsShow = ref(false)
const coachHourImportIsShow = ref(false)
const parentInfoExportShow = ref(false)
const trainingPlanUploadShow = ref(false)
const trainingPlanExportShow = ref(false)
const onCampusTrainingShow = ref(false)
const offCampusTrainingShow = ref(false)
const uniappHomeManageShow = ref(false)
const rollingInfoManageShow = ref(false)
const appletImgManageShow = ref(false)
const parentRegisterInfoExportShow = ref(false)
const userSigInListShow = ref(false)
const carouselImageManageShow = ref(false)
const scheduleEnterSchoolShow = ref(false)
const scheduleTrainShow = ref(false)
const scheduleTrainMaintenanceShow = ref(false)
const todoMessageShow = ref(false)
const updateMessageShow = ref(false)
const assessmentWarningShow = ref(false)
const assessmentPlanWarningShow = ref(false)
const assessmentWagesShow = ref(false)
const assessmentResultsShow = ref(false)
const programmeAchieveShow = ref(false)
const summaryReportShow = ref(false)
const manageSystemShow = ref(false)
const jobManualShow = ref(false)
const preJobLearningShow = ref(false)
const cultureShow = ref(false)
const brandShow = ref(false)
const publicizeShow = ref(false)
const swiperImgShow = ref(false)
//支付导出
const studentPayClick =()=>{
  window.open('https://hsczty.com/api/training/results/exportre');
}
// 学生基础信息录入
const studentBaseInfoClick = () => {
  studentBaseInfoImportIsShow.value = true
}
// 学生训练成绩录入
const studentTrainingResultsImportClink = () => {
  studentTrainingResultsImportIsShow.value = true
}
// 学生训练成绩导出
const studentTrainingResultsExportClink = () => {
  studentTrainingResultsExportIsShow.value = true
}
// 教练课时导入
const coachHourExportClink = () => {
  coachHourImportIsShow.value = true
}
// 家长信息下载
const parentInfoImportClink = () => {
  parentInfoExportShow.value = true
}
// 训练计划上传
const trainingPlanUpload = () => {
  trainingPlanUploadShow.value = true
}
// 训练计划下载
const trainingPlanExport = () => {
  trainingPlanExportShow.value = true
}
// 校内培训
const OnCampusTrainingClink = () => {
  onCampusTrainingShow.value = true
}
// 校外培训
const offCampusTrainingClink = () => {
  offCampusTrainingShow.value = true
}
// 小程序管理
const uniappHomeManageClink = () => {
  uniappHomeManageShow.value = true
}
// 小程序滚动消息管理
// const rollingInfoManageClink = () => {
//   rollingInfoManageShow.value = true
// }
// 家长注册信息
const parentRegisterInfoExportClink = () => {
  parentRegisterInfoExportShow.value = true
}
// 打卡列表
// const sigInClick = () => {
//   userSigInListShow.value = true
// }
// 轮播图管理
const appletImgClink = () => {
  appletImgManageShow.value = true
}
// 进校数据上传
const scheduleEnterSchoolClick = () => {
  scheduleEnterSchoolShow.value = true
}
// 训练数据上传
const scheduleTrainClick = () => {
  scheduleTrainShow.value = true
}
// 运维数据上传
const scheduleTrainMaintenanceClick = () => {
  scheduleTrainMaintenanceShow.value = true
}
// 待办提醒
const todoMessageClick = () => {
  todoMessageShow.value = true
}
// web轮播图
const carouselImageManageClick = () => {
  swiperImgShow.value = true
}
// 更新提醒
const updateMessageClick = () => {
  updateMessageShow.value = true
}
// 考核预警
const assessmentWarningClick = () => {
  assessmentWarningShow.value = true
}
// 方案预警
const assessmentPlanWarningClick = () => {
  assessmentPlanWarningShow.value = true
}
// 薪资预结
const assessmentWagesClick = () => {
  assessmentWagesShow.value = true
}
// 考核结果
const assessmentResultsClick = () => {
  assessmentWagesShow.value = true
}
// 方案兑现
const programmeAchieveClick = () => {
  assessmentWagesShow.value = true
}
// 汇总报表
const summaryReportClick = () => {
  assessmentWagesShow.value = true
}
// 管理制度
const manageSystemClick = () => {
  manageSystemShow.value = true
}
// 岗位手册
const jobManualShowClick = () => {
  jobManualShow.value = true
}
// 上岗学习
const preJobLearningClick = () => {
  preJobLearningShow.value = true
}
// 文化
const cultureClick = () => {
  cultureShow.value = true
}
// 品牌
const brandClick = () => {
  brandShow.value = true
}
// 宣传
const publicizeClick = () => {
  publicizeShow.value = true
}
</script>
<template>
  <StudentBaseInfoImport v-model:visible="studentBaseInfoImportIsShow" />
  <StudentTrainingResultsImport v-model:visible="studentTrainingResultsImportIsShow" />
  <StudentTrainingResultsExport v-model:visible="studentTrainingResultsExportIsShow" />
  <CoachHourImport v-model:visible="coachHourImportIsShow" />
  <ParentInfoExport v-model:visible="parentInfoExportShow" />
  <TrainingPlanUpload v-model:visible="trainingPlanUploadShow" />
  <TrainingPlanExport v-model:visible="trainingPlanExportShow" />
  <OnCampusTraining v-model:visible="onCampusTrainingShow" />
  <OffCampusTraining v-model:visible="offCampusTrainingShow" />
  <UniappHomeManage v-model:visible="uniappHomeManageShow" />
  <RollingInfoManage v-model:visible="rollingInfoManageShow" />
  <AppletImgManage v-model:visible="appletImgManageShow" />
  <ParentRegisterInfoExport v-model:visible="parentRegisterInfoExportShow" />
  <UserSigInList v-model:visible="userSigInListShow" />
  <CarouselImageManage v-model:visible="carouselImageManageShow" />
  <ScheduleEnterSchool v-model:visible="scheduleEnterSchoolShow" />
  <ScheduleTrain v-model:visible="scheduleTrainShow" />
  <ScheduleTrainMaintenance v-model:visible="scheduleTrainMaintenanceShow" />
  <TodoMessage v-model:visible="todoMessageShow" />
  <UpdateMessage v-model:visible="updateMessageShow" />
  <AssessmentWarning v-model:visible="assessmentWarningShow" />
  <AssessmentPlanWarning v-model:visible="assessmentPlanWarningShow" />
  <AssessmentWages v-model:visible="assessmentWagesShow" />
  <AssessmentResults v-model:visible="assessmentResultsShow" />
  <ProgrammeAchieve v-model:visible="programmeAchieveShow" />
  <SummaryReport v-model:visible="summaryReportShow" />
  <ManageSystem v-model:visible="manageSystemShow" />
  <JobManual v-model:visible="jobManualShow" />
  <PreJobLearning v-model:visible="preJobLearningShow" />
  <Culture v-model:visible="cultureShow" />
  <Brand v-model:visible="brandShow" />
  <Publicize v-model:visible="publicizeShow" />
  <SwiperImg v-model:visible="swiperImgShow" />
  <div class="mainDiv">
    <el-menu
      :default-active="activeIndex2"
      class="el-menu-demo center-menu"
      mode="horizontal"
      background-color="#f26522"
      text-color="#fff"
      active-text-color="#ffd04b"
      @select="handleSelect"
    >
      <el-sub-menu index="1">
        <template #title>客服专栏</template>
        <el-sub-menu index="1-1">
          <template #title>数据上传</template>
          <el-menu-item index="1-1-1" @click="studentBaseInfoClick">学生基础信息</el-menu-item>
          <el-menu-item index="1-1-2" @click="studentTrainingResultsImportClink">学生训练成绩</el-menu-item>
          <el-menu-item index="1-1-3">学校开课计划</el-menu-item>
          <el-menu-item index="1-1-4" @click="coachHourExportClink">教练训练课时</el-menu-item>
          <el-menu-item index="1-1-5">学生训练影像素材</el-menu-item>
        </el-sub-menu>
        <el-sub-menu index="1-2">
          <template #title>数据下载</template>
          <el-menu-item index="1-1-1" @click="studentPayClick">支付信息导出</el-menu-item>
          <el-menu-item index="1-2-1" @click="parentInfoImportClink">学生基础信息</el-menu-item>
          <el-menu-item index="1-2-2" @click="parentRegisterInfoExportClink">家长绑定信息</el-menu-item>
          <el-menu-item index="1-2-3" @click="studentTrainingResultsExportClink">学生测试成绩</el-menu-item>
        </el-sub-menu>
        <el-sub-menu index="1-3">
          <template #title>小程序管理</template>
          <el-menu-item index="1-3-1" @click="appletImgClink">轮播图管理</el-menu-item>
          <!--          <el-menu-item index="1-3-2" @click="rollingInfoManageClink">信息管理</el-menu-item>-->
          <el-menu-item index="1-3-3" @click="uniappHomeManageClink">用户管理</el-menu-item>
          <!--          <el-menu-item index="1-3-4" @click="sigInClick">打卡用户列表</el-menu-item>-->
        </el-sub-menu>
        <el-menu-item index="1-4">客服操作手册</el-menu-item>
      </el-sub-menu>
      <el-sub-menu index="2">
        <template #title>运营专栏</template>
        <el-sub-menu index="2-1">
          <template #title>数据上传</template>
          <el-menu-item index="2-1-1">学校基础信息</el-menu-item>
          <el-menu-item index="2-1-2">学校运营周计划</el-menu-item>
          <el-menu-item index="2-1-3">学校运营周检视表</el-menu-item>
        </el-sub-menu>
        <el-menu-item index="2-2">运营手册</el-menu-item>
      </el-sub-menu>
      <el-sub-menu index="3">
        <template #title>教练专栏</template>
        <el-sub-menu index="3-1">
          <template #title>训练工具包</template>
          <el-menu-item index="3-1-1" @click="trainingPlanUpload">上传</el-menu-item>
          <el-menu-item index="3-1-2" @click="trainingPlanExport">下载</el-menu-item>
        </el-sub-menu>
        <el-menu-item index="3-2" @click="OnCampusTrainingClink">校内公益训练</el-menu-item>
        <el-menu-item index="3-3" @click="offCampusTrainingClink">校外核心训练</el-menu-item>
      </el-sub-menu>
      <el-sub-menu index="4">
        <template #title>人事专栏</template>
        <el-sub-menu index="4-1">
          <template #title>数据上传</template>
          <el-menu-item index="4-1-1">人员信息表</el-menu-item>
          <el-menu-item index="4-1-2">入职线上流程</el-menu-item>
          <el-menu-item index="4-1-3">离职线上流程</el-menu-item>
          <el-menu-item index="4-1-4">月度考勤统计</el-menu-item>
        </el-sub-menu>
        <el-sub-menu index="4-2">
          <template #title>制度、手册、方案</template>
          <el-menu-item index="4-2-1">前台员工管理办法</el-menu-item>
          <el-menu-item index="4-2-2">前台员工差勤管理办法</el-menu-item>
          <el-menu-item index="4-2-3">后台员工管理办法</el-menu-item>
          <el-menu-item index="4-2-4">后台员工差勤管理办法</el-menu-item>
        </el-sub-menu>
      </el-sub-menu>
      <el-sub-menu index="5">
        <template #title>薪资核算</template>
        <el-sub-menu index="5-1">
          <template #title>数据上传</template>
          <el-menu-item index="5-1-1">人员薪资预警</el-menu-item>
          <el-menu-item index="5-1-2">前台人员薪资统计</el-menu-item>
          <el-menu-item index="5-1-3">月度教练薪资统计</el-menu-item>
          <el-menu-item index="5-1-4">后台人员薪资统计</el-menu-item>
        </el-sub-menu>
        <el-sub-menu index="5-2">
          <template #title>数据下载</template>
          <el-menu-item index="5-2-1">前台薪资月度</el-menu-item>
          <el-menu-item index="5-2-2">前台薪资日预结</el-menu-item>
        </el-sub-menu>
        <el-menu-item index="5-3">激励方案</el-menu-item>
      </el-sub-menu>
      <el-sub-menu index="6">
        <template #title>考核预警</template>
        <el-sub-menu index="6-1">
          <template #title>数据上传</template>
          <el-menu-item index="6-1-1">前台人员月度预警</el-menu-item>
          <el-menu-item index="6-1-2">前台人员季度考核</el-menu-item>
        </el-sub-menu>
        <el-sub-menu index="6-2">
          <template #title>数据下载</template>
          <el-menu-item index="6-2-1">前台月度预警</el-menu-item>
          <el-menu-item index="6-2-2">前台季度考核</el-menu-item>
        </el-sub-menu>
        <el-menu-item index="6-3">考核方案</el-menu-item>
      </el-sub-menu>
      <el-sub-menu index="7">
        <template #title>行政专栏</template>
        <el-sub-menu index="7-1">
          <template #title>数据上传</template>
          <el-menu-item index="7-1-1">物品清单统计</el-menu-item>
          <el-menu-item index="7-1-2">物品申请单</el-menu-item>
          <el-menu-item index="7-1-3">费用申请单</el-menu-item>
          <el-menu-item index="7-1-4">费用报销单</el-menu-item>
          <el-menu-item index="7-1-5">月度成本表</el-menu-item>
        </el-sub-menu>
        <el-sub-menu index="7-2">
          <template #title>制度、手册、方案</template>
          <el-menu-item index="7-2-1">物品管理制度</el-menu-item>
          <el-menu-item index="7-2-2">财务管理制度</el-menu-item>
        </el-sub-menu>
      </el-sub-menu>
      <el-sub-menu index="8">
        <template #title>品宣专栏</template>
        <el-menu-item index="8-1">信息公示</el-menu-item>
        <el-menu-item index="8-2">公司宣传文案</el-menu-item>
        <el-menu-item index="8-3">学校数据总览</el-menu-item>
        <el-menu-item index="8-4">H5宣传素材</el-menu-item>
        <el-menu-item index="8-5">优秀影像汇总</el-menu-item>
        <el-menu-item index="8-6">公司品宣管理制度</el-menu-item>
      </el-sub-menu>
      <el-sub-menu index="9">
        <template #title>数据配置</template>
        <el-sub-menu index="9-0">
          <template #title>轮播图</template>
          <el-menu-item index="9-0-1" @click="carouselImageManageClick">轮播图管理</el-menu-item>
        </el-sub-menu>
        <el-sub-menu index="9-1">
          <template #title>行事历</template>
          <el-menu-item index="9-1-1" @click="scheduleEnterSchoolClick">进校</el-menu-item>
          <el-menu-item index="9-1-2" @click="scheduleTrainClick">训练</el-menu-item>
          <el-menu-item index="9-1-3" @click="scheduleTrainMaintenanceClick">运维</el-menu-item>
        </el-sub-menu>
        <el-sub-menu index="9-2">
          <template #title>消息提醒</template>
          <el-menu-item index="9-2-1" @click="todoMessageClick">待办提醒</el-menu-item>
          <el-menu-item index="9-2-2" @click="updateMessageClick">更新提醒</el-menu-item>
        </el-sub-menu>
        <el-sub-menu index="9-3">
          <template #title>月度预核</template>
          <el-menu-item index="9-3-1" @click="assessmentWarningClick">考核预警</el-menu-item>
          <el-menu-item index="9-3-2" @click="assessmentPlanWarningClick">方案预警</el-menu-item>
          <el-menu-item index="9-3-3" @click="assessmentWagesClick">薪资预结</el-menu-item>
        </el-sub-menu>

        <el-sub-menu index="9-4">
          <template #title>公示查询</template>
          <el-menu-item index="9-4-1" @click="assessmentResultsClick">考核结果</el-menu-item>
          <el-menu-item index="9-4-2" @click="programmeAchieveClick">方案兑现</el-menu-item>
          <el-menu-item index="9-4-3" @click="summaryReportClick">总和报表</el-menu-item>
        </el-sub-menu>
        <el-sub-menu index="9-5">
          <template #title>公司资讯</template>
          <el-menu-item index="9-5-1" @click="manageSystemClick">管理制度</el-menu-item>
          <el-menu-item index="9-5-2" @click="jobManualShowClick">岗位手册</el-menu-item>
          <el-menu-item index="9-5-3" @click="preJobLearningClick">上岗学习</el-menu-item>
        </el-sub-menu>
        <el-sub-menu index="9-6">
          <template #title>公司品宣</template>
          <el-menu-item index="9-6-1" @click="cultureClick">文化</el-menu-item>
          <el-menu-item index="9-6-2" @click="brandClick">品牌</el-menu-item>
          <el-menu-item index="9-6-3" @click="publicizeClick">宣传</el-menu-item>
        </el-sub-menu>
        <el-sub-menu index="9-7">
          <template #title>常用功能</template>
          <el-menu-item index="9-7-1">OA申请</el-menu-item>
          <el-menu-item index="9-7-2">数据导入</el-menu-item>
        </el-sub-menu>
        <el-sub-menu index="9-8">
          <template #title>家长自愿绑定信息</template>
          <el-menu-item index="9-8-1">信息管理</el-menu-item>
        </el-sub-menu>
      </el-sub-menu>
      <el-sub-menu index="10">
        <template #title>系统设置</template>
        <el-menu-item index="10-1">用户管理</el-menu-item>
        <el-menu-item index="10-2">区域管理</el-menu-item>
        <el-menu-item index="10-3">学校管理</el-menu-item>
      </el-sub-menu>
    </el-menu>
  </div>
</template>

<style>
.center-menu {
  display: flex; /* 使用flex布局 */
  justify-content: center; /* 水平居中 */
}
</style>
